import "components/NavBar.scss";
import { Tag, TagColor } from "constants/enum";
import React from "react";
import { Button } from "react-bootstrap";
import { getContrastColor } from "utils/color";

interface Props {
  tag: Tag;
}
export const TagLabel = (props: Props) => {
  const { tag } = props;

  const color = TagColor[tag];

  // TODO: link to blog by tag

  return (
    <Button
      variant="flat"
      style={{
        color: getContrastColor(color),
        backgroundColor: color,
      }}
      id="tag-label"
      className="py-0"
      // href="/blog"
    >
      {tag}
    </Button>
  );
};
