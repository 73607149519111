import { PAGE_SIZE, Path } from "constants/constant";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useHistory, useLocation } from "react-router-dom";
import { contentService } from "services/content.service";
import { BirdRecord } from "types/types";
import "../App.scss";
import { typography } from "types/About.type";
import { Buymeacoffee } from "components/SupportButton";

export const BirdPage = () => {
  const [birdRecords, setBirdRecords] = useState<BirdRecord[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const location = useLocation();
  const limit = PAGE_SIZE;

  const page = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("page")) || 1;
  }, [location.search]);

  const fetchBirdRecords = useCallback(async () => {
    setLoading(true);
    const [birdRecords, totalCount] = await contentService.listBirdRecords(
      (page - 1) * limit,
      limit,
    );
    setTotalCount(totalCount);
    setBirdRecords(birdRecords);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    fetchBirdRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div id="wrapper">
      <Buymeacoffee />

      <Container style={{ padding: "2rem 1rem" }}>
        <h1>Birds</h1>
        {page == 1 && <p>{typography.bird.description}</p>}
        <Row style={{ padding: "1rem 0" }}>
          {!loading &&
            birdRecords.map((birdRecord) => (
              <Col
                style={{ padding: "1rem 0.5rem" }}
                md={4}
                key={birdRecord.nameEN}
              >
                <Image fluid src={birdRecord.images[0]} />
                <div
                  style={{
                    color: "#242424",
                    padding: "1rem 0.5rem",
                  }}
                >
                  <h6>{birdRecord.nameEN}</h6>
                  <p>{birdRecord.nameTH}</p>
                </div>
              </Col>
            ))}
        </Row>

        <div
          style={{
            justifyContent: "center",
            width: "100%",
            display: "flex",
          }}
        >
          <PaginationControl
            page={page}
            between={4}
            total={totalCount}
            limit={limit}
            changePage={(page) => {
              history.push(`${Path.BIRDS}?page=${page}`);
            }}
            ellipsis={1}
          />
        </div>
      </Container>
    </div>
  );
};
