import "App.scss";
import { Space } from "components/Space";
import { Path } from "constants/constant";
import { Routes } from "constants/routes";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { typography } from "types/About.type";

export const HomePage = () => {
  const links = [
    {
      title: "Blog",
      href: "https://medium.com/@kritkruaykitanon",
    },
    {
      title: "Gallery",
      href: Path.GALLERY,
    },
    {
      title: "Birds",
      href: Path.BIRDS,
    },
  ];
  return (
    <div id="wrapper" className="center">
      <div className="circle mb-3">
        <img src="myself.jpeg" className="App-logo" alt="logo" />
      </div>
      <h2>Krit Kruaykitanon</h2>
      {/* <Container>
        <Row className="justify-content-center py-3">
          <Col md="8">
 <p>{typography.name.description}</p> 
 <p>{typography.visitedCountries}</p> 
          </Col>
        </Row>
      </Container> 
      */}
      <div className="home-links">
        {links.map((link) => (
          <a href={link.href} key={link.href}>
            {link.title}
          </a>
        ))}
      </div>
      {/* <Link to="/blog">
        <Button variant="primary" size="lg" className="home-btn">
          Enter
        </Button>
      </Link> */}
    </div>
  );
};
