import "App.scss";
import { Footer } from "components/Footer";
import { Navbar } from "components/NavBar";
import { SEO } from "components/SEO";
import { NavbarOption } from "constants/enum";
import { Routes } from "constants/routes";
import { Route, Switch } from "react-router-dom";

const App = () => {
  return (
    <div id="app-wrapper">
      <SEO />
      <Navbar option={NavbarOption.WHITE} />
      <Switch>
        {Routes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
